<template>
  <router-view></router-view>
</template>

<script lang="ts">
export default {

}
</script>

<style lang="scss">

</style>
